@charset 'utf-8';

.img-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ($grid-gutter-width / 2);
}

.img-placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: darken($teal-100, 10%);
}

.img-bordered {
  border: $border-width solid $white;
  border-radius: $border-radius-lg;
  overflow: hidden;
}

// Photoswipe
/////////////

.gallery-item {
  display: flex;
  flex: 1 1 auto;

  figcaption {
    display: none;
  }
}
