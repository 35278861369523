@charset 'utf-8';

// Card Pill
////////////

// Mixin to set the pill gradient
@mixin card-pill-bg-gradient($class, $color: $card-cap-bg) {
  #{$class} {
    background-color: transparent;
    background-image: linear-gradient(to right, rgba($color, 0) 5%, rgba($color, 0.08) 50%);
  }

  a#{$class} {
    &:active,
    &.active {
      transform: scale(0.98);
      background-image: linear-gradient(to right, rgba($color, 0.02) 5%, rgba($color, 0.3) 70%);
    }
  }
}

// Card border radius has to be smaller to correspond to the card-pill
.card {
  width: 100%;

  @include border-radius($card-border-radius - $card-border-width);
}

a.card {
  @include hover {
    text-decoration: none;
  }

  &:active,
  &.active {
    transform: scale(0.98);
  }
}

.card-img {
  @include border-radius($card-border-radius - $card-border-width * 2);
}

// The card pill displays an icon at the left
.card-pill {
  display: flex;
  position: relative;
  align-items: center;
  margin: -$card-border-width;
  border: $card-border-width solid $card-cap-bg;
  background-color: $card-cap-bg;
  font-weight: $font-weight-bold;
  color: color("light");

  @include border-radius($card-border-radius);

  &.card-pill-no-icon {
    padding-left: $card-spacer-x;
  }

  .flag {
    margin: ($spacer * -2.4) ($spacer * 0.5) ($spacer * -3) auto;

    @include media-breakpoint-up(lg) {
      margin-right: ($spacer * 2);
    }

    @include flag-size(60px);
  }

  > .btn {
    &:active {
      transform: none;
    }
  }

  @each $color, $value in $theme-colors {
    .card-list-item-#{$color} & {
      background-color: $value;
      border-color: $value;
    }
  }
}

.card-pill-white {
  background-color: color("light");
  color: $card-cap-bg;
}

a[href].card-pill {
  @include hover-focus {
    text-decoration: none;
    color: color("light");
  }
}

// Variation of the pill with Primary colors
.card-pill-simple {
  border: 0;
  font-weight: $font-weight-normal;
  color: theme-color("primary");

  .card-pill-icon .icon {
    color: theme-color("primary");
  }

  .card:not(.card-open) & .card-pill-title {
    color: theme-color("primary") !important;
  }
}

@include card-pill-bg-gradient(".card-pill-simple");

a[href].card-pill-simple {
  @include hover-focus {
    text-decoration: none;
    color: theme-color("primary");
  }

  &[data-toggle="collapse"] {
    margin: 0;

    .card-open & {
      margin: -$card-border-width;
      background: theme-color("secondary");
      color: $white;

      @include hover {
        color: $white;
      }

      .card-pill-icon .icon {
        color: theme-color("secondary");

        svg {
          max-width: 32px;
          max-height: 32px;
        }
      }

      .card-pill-icon:not(.card-pill-icon-lg) .icon {
        border: $card-border-width solid theme-color("secondary");
      }

      .text-dark {
        color: $white !important;
      }
    }
  }
}

.card-pill-title {
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;

    // space the spans correctly inside card-pill title
    &:not(:last-child) {
      margin-right: $card-spacer-x * 2;

      @include media-breakpoint-down(sm) {
        margin-right: $card-spacer-x;
      }
    }
  }

  .lead {
    line-height: 1.1;
  }

  // Make sure when we're centering the text, the icon is not pushing things in
  // one direction
  &.mx-auto {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 calc(#{$card-pill-icon-size} + #{$card-spacer-x});
    text-align: center;
  }
}

.size-date {
  display: inline-block;
  width: 11em;
  flex-shrink: 0;

  &:not(:first-child) {
    margin-left: ($spacer * 0.5);
  }

  @include media-breakpoint-down(sm) {
    width: 7em;
  }

  time {
    white-space: nowrap;
  }
}

// The icon, pushed against the left border
.card-pill-icon {
  line-height: 0;
  margin-right: $card-spacer-x;

  .icon {
    justify-content: center;
    align-items: center;
    width: $card-pill-icon-size;
    height: $card-pill-icon-size;
    border-radius: 50%;
    background-color: color("light");
    color: $card-cap-bg;

    svg {
      width: $card-pill-icon-size * 0.6875;
      height: $card-pill-icon-size * 0.6875;
    }
  }
}

.card-pill-icon-lg {
  margin-right: $card-spacer-x * 0.5;

  .icon {
    svg {
      width: $card-pill-icon-size;
      height: $card-pill-icon-size;
    }
  }
}

// Card
///////

.card-title {
  margin-top: $card-spacer-y * 2;
  margin-bottom: $card-spacer-y * 3;
  text-align: center;
}

.card-icon {
  text-align: center;

  @include icon-size(60px);
}

.card-actions {
  margin-top: $card-spacer-y;
}

.card-actions-offset {
  margin-bottom: -$card-spacer-y;
}

.card-btn-corner {
  position: absolute;
  right: -$card-border-width;
  bottom: -$card-border-width;
}

// Card List Item
/////////////////

// This wraps the list items
.card-list {
  display: flex;
  flex-direction: column;
  perspective: 2000px;

  @include list-unstyled();
}

// When the card is only a pill in a list, closed state, hides the card-body
// add .open class to animate opening
.card-list-item {
  flex: 1 0 auto;
  margin-bottom: $spacer;

  // Apply some styles when card is ONLY NOT OPEN
  &:not(.card-open) {
    background: none;
    border-color: transparent;

    .card-pill-icon:not(.card-pill-icon-lg) {
      .icon {
        background-color: $card-cap-bg;
        color: color("light");
      }
    }

    .card-pill {
      padding: 0;
      border-color: $white;
    }

    .card-pill-title {
      color: $body-color;
    }

    @include card-pill-bg-gradient(".card-pill");
  }

  .card-pill {
    padding-top: 0;
    padding-bottom: 0;
  }

  // Hide the button at the right of the pill ONLY WHEN OPEN
  &.card-open {
    .card-pill {
      border: $card-border-width solid $card-cap-bg;

      .btn {
        opacity: 0;
      }
    }
  }

  .flag {
    @include flag-size(50px);
  }

  @each $color, $value in $theme-colors {
    $light: color('#{$color}-light');

    .bg-#{$color} & {
      .card-pill {
        background-color: transparent;
        background-image: linear-gradient(to right, rgba($light, 0.8) 50%, $value 95%);
      }

      a.card-pill {
        &:active,
        &.active {
          transform: scale(0.98);
          background-image: linear-gradient(to right, $light 50%, $value 70%);
        }
      }

      .card-pill-title {
        color: $white;
      }

      .card-pill-icon {
        margin-top: (-$card-border-width);
        margin-bottom: (-$card-border-width);
        margin-left: (-$card-border-width);
      }

      &:not(.card-open) {
        .card-pill-icon {
          .icon {
            background-color: transparent !important;
          }
        }
      }

      .btn {
        margin: (-$card-border-width) (-$card-border-width) (-$card-border-width) 0;
      }

      &.card-open {
        background-color: $light;
        border-color: $white;

        .card-pill {
          background: $white;
          border-color: $white;
        }

        .card-pill-title {
          color: $body-color;

          .lead {
            color: $value;
          }
        }

        .card-pill-icon {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;

          .icon {
            width: ($card-pill-icon-size - $card-border-width);
            height: ($card-pill-icon-size - $card-border-width);
            background-color: $light;
            color: $white;

            svg {
              width: ($card-pill-icon-size * 0.6875);
              height: ($card-pill-icon-size * 0.6875);
            }
          }
        }
      }
    }

    &.card-list-item-#{$color}.card-open {
      .card-pill {
        border-color: $value;
      }
    }
  }
}

.card-list-simple-item {
  margin-bottom: calc(#{$spacer} + #{$card-border-width * 4});

  &:not(.card-open) {
    background: none;
    border: 0;
  }

  .flag {
    @include flag-size(50px);
  }

  .card-pill-title {
    margin-right: auto;
  }
}

.card-map {
  position: relative;
  margin-top: $spacer * -1;
  width: 100%;
  height: 600px;
}

.card-back-map .card-body {
  position: relative;
  z-index: -1;
  margin-top: -1rem;
  padding: 0;
  overflow: hidden;
  border-bottom-right-radius: ($card-border-radius - $card-border-width);
  border-bottom-left-radius: ($card-border-radius - $card-border-width);

  .card-pill {
    position: relative;
    z-index: 100;
  }
}

.card-map-footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  pointer-events: none;

  .btn {
    pointer-events: auto;
  }
}

// Invert colors with theme colors
@each $color, $value in $theme-colors {
  .card-#{$color} {
    background: color(#{$color}-lighter);
    border-color: $value;

    // Automaticly calculate color value based on contrast with background
    @include color-contrast($value);

    // Base is secondary color, just apply to other colors
    @if ($color != 'secondary') {
      .card-pill {
        background-color: $value;
        border-color: $value;
      }

      .card-pill-simple {
        background: none;
        background-image: linear-gradient(to right, rgba($value, 0) 5%, rgba($value, 0.08) 50%);
      }

      .card-pill-icon {
        .icon {
          color: $value;
        }
      }

      .card-pill-simple {
        color: $value;
      }

      &.card-list-item {
        &:not(.card-open) {
          .card-pill {
            background-image: linear-gradient(to right, rgba($value, 0) 5%, rgba($value, 0.08) 50%);
          }

          a.card-pill {
            &:active,
            &.active {
              background-image: linear-gradient(to right, rgba($value, 0.02) 5%, rgba($value, 0.3) 70%);
            }
          }

          .card-pill-icon {
            .icon {
              background-color: $value;
            }
          }
        }

        &.card-open {
          .card-pill {
            border-color: $value;
          }
        }
      }

      &.card-list-simple-item {
        a.card-pill {
          @include hover-focus-active {
            color: $value;
          }

          &:active,
          &.active {
            background-image: linear-gradient(to right, rgba($value, 0.02) 5%, rgba($value, 0.3) 70%);
          }
        }
      }
    }
  }
}

// Animations
.card-flippable {
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(1px);
  transition: transform 0.4s;

  &:hover {
    will-change: transform;
  }

  .card-back,
  .card-front,
  .collapse {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backface-visibility: hidden;
    z-index: 0;
  }

  .card-back {
    position: absolute;
    top: -$card-border-width;
    right: -$card-border-width;
    bottom: -$card-border-width;
    left: -$card-border-width;
    z-index: 10;
    transform: rotateX(180deg) translateZ(1px);
    background-color: $card-bg;

    @include border-radius($card-border-radius - $card-border-width);

    &:not(:target) {
      display: none;
    }
  }

  &.flip {
    z-index: 10;
    transform: rotateX(-180deg) translateZ(1px);

    .card-front,
    .collapse {
      position: absolute;
    }

    .collapse {
      top: $card-pill-icon-size; // keep the collapse below the .card-pill
      opacity: 0;
    }

    .card-back:target {
      position: static;
    }

    .card-front .flag {
      opacity: 0;
    }
  }
}

.card-fadeout {
  animation: fadeout 0.4s forwards;
}

@keyframes fadeout {
  0% {
    flex-basis: auto;
    opacity: 1;
    border-width: $card-border-width;
    margin-bottom: $spacer;
  }

  75% {
    flex-basis: auto;
    opacity: 0;
    border-width: $card-border-width;
  }

  100% {
    flex-basis: 0;
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
    border-width: 0;
  }
}
