@charset 'utf-8';

.form-multistep {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  .form-wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }

  > .row,
  > .tab-content {
    flex: 1 1 auto;
  }
}
