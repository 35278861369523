@charset 'utf-8';

$calendar-item-size: 3.125rem;

.calendar-wrapper {
  text-align: center;
}

@supports (grid-template-columns: auto) {
  .calendar {
    display: inline-grid;
    grid-template-columns: repeat(7, $calendar-item-size);
    grid-auto-rows: 1fr;
    grid-gap: $grid-gutter-width 3rem;
    margin: 0 auto;

    @include media-breakpoint-only(sm) {
      grid-gap: ($grid-gutter-width / 2) 1.5rem;
    }

    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 2vw;
    }
  }

  .calendar-item {
    min-width: $calendar-item-size;
    min-height: $calendar-item-size;

    @include media-breakpoint-down(xs) {
      min-width: 10vw;
      min-height: 10vw;
    }

    &.active {
      width: 7rem;
      margin-right: 0;
      margin-left: ((7rem - $calendar-item-size) / -2); // center it (7 - 3.125) / -2

      @include media-breakpoint-only(sm) {
        width: 5rem;
        margin-left: ((5rem - $calendar-item-size) / -2); // center it (4 - 3.125) / 2
      }

      @include media-breakpoint-down(sm) {
        width: auto;
        margin: 0;
      }
    }
  }

  .calendar-head {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: $calendar-item-size;
    height: $calendar-item-size;
    margin: 0 2% 2%;
    color: gray('600');

    @include media-breakpoint-down(xs) {
      min-width: 10vw;
      min-height: 10vw;
    }
  }
}

@supports not (display: grid) {
  .calendar {
    width: calc(7 * #{$calendar-item-size} + 7 * #{$grid-gutter-width});

    @include make-row();

    display: inline-flex; // stylelint-disable-line

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .calendar-item {
    flex: 0 0 calc(#{percentage(1 / 7)} - #{$grid-gutter-width / 2});
    width: 100%;
    min-width: 20px;
    max-width: calc(#{percentage(1 / 7)} - #{$grid-gutter-width / 2});
    height: $calendar-item-size;
    margin: ($grid-gutter-width / 6) ($grid-gutter-width / 4);
    padding: 0;

    @include media-breakpoint-up(md) {
      flex: 0 0 calc(#{percentage(1 / 7)} - #{$grid-gutter-width});
      max-width: calc(#{percentage(1 / 7)} - #{$grid-gutter-width});
      margin: ($grid-gutter-width / 4) ($grid-gutter-width / 2);

      &.active {
        flex-basis: 6.5rem;
        max-width: 6.5rem;
        margin-right: calc((6.5rem - #{$calendar-item-size}) / -2 + #{$grid-gutter-width} / 2);
        margin-left: calc((6.5rem - #{$calendar-item-size}) / -2 + #{$grid-gutter-width} / 2);
      }
    }
  }

  .calendar-head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 calc(#{percentage(1 / 7)} - #{$grid-gutter-width / 2});
    min-width: 20px;
    width: 100%;
    max-width: calc(#{percentage(1 / 7)} - #{$grid-gutter-width / 2});
    margin: ($grid-gutter-width / 6) ($grid-gutter-width / 4);
    border: 2px solid transparent;
    color: gray('600');

    @include media-breakpoint-up(md) {
      flex: 0 0 calc(#{percentage(1 / 7)} - #{$grid-gutter-width});
      max-width: calc(#{percentage(1 / 7)} - #{$grid-gutter-width});
      margin: ($grid-gutter-width / 4) ($grid-gutter-width / 2);
    }
  }
}

.calendar-item {
  position: relative;
  overflow: visible;

  .flag {
    position: absolute;
    top: 50%;
    transform: translateY(-42.5%);
    pointer-events: none;

    @include flag-size(5rem);
  }

  .icon {
    transform: scaleY(1.2);
  }

  .flag-number {
    position: absolute;
    z-index: 1;
    top: 43%;
    left: 50%;
    transform: translateX(-50%);
  }
}
