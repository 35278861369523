@charset "utf-8";

// Handle shadows on elements, with brand-primary color
.shadow-to-right {
  @include drop-shadow(bottom);

  @include media-breakpoint-up(md) {
    @include drop-shadow(right);
  }
}

.shadow-to-left {
  @include drop-shadow(bottom);

  @include media-breakpoint-up(make-col-modifier) {
    @include drop-shadow(left);
  }
}

.shadow-to-bottom {
  @include drop-shadow(bottom);
}

.shadow-to-bottom-secondary {
  @include drop-shadow(bottom, theme-color('secondary'));
}

.shadow-floating {
  @include floating-shadow();
}

// Positionning
.over-modal {
  z-index: $zindex-modal - 5;
}

// Background helpers
@include bg-variant('.bg-primary', color("primary"));
@include bg-variant('.bg-primary-light', color("primary-lighter"));

// Light colors on colored backgrounds
.bg-secondary,
.bg-info,
.bg-primary,
.bg-danger {
  color: color("light");

  .text-muted {
    color: rgba(color("light"), 0.75) !important;
  }
}

.bg-gradient {
  background-image: linear-gradient(to top, $purple-400, #934bcb, $blue, #66d4ea, #5cdac2);
}

.fullpage-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}

.sticky-top {
  // We sometimes have a small pixel gap on top...
  top: -1px;
  padding-top: 1px;
}

.sticky-bottom {
  @supports (position: sticky) {
    position: sticky;
    bottom: 0;
    z-index: $zindex-sticky;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }
}

// override Bootstrap rounded class
.rounded {
  border-radius: $spacer !important;
}

// Text helpers
@include text-emphasis-variant('.text-dark', color("dark"));
@include text-emphasis-variant('.text-gray', $gray-700);
@include text-emphasis-variant('.text-warning', color("warning"));

// Make sure the links inside .text-white are all white.
// We avoid adding the classes in string translations that way (homepage sponsors)
.text-white a {
  color: $white !important;

  @include hover-focus-active {
    color: $white !important;
  }
}

// Click helpers
.pointers-none {
  pointer-events: none;
}
