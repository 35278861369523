@charset 'utf-8';

.nav {
  margin-bottom: $spacer;
}

// Navbar Toggle Button
.navbar-toggle {
  display: inline-flex;
  position: relative;
  z-index: 1;
  width: $navbar-icon-size;
  height: $navbar-icon-size;
  min-width: initial;
  margin-bottom: ($grid-gutter-width / 2);
  padding: 0;
  background: $white;
  color: theme-color("primary");
  overflow: visible;

  @include drop-shadow(bottom);

  &:hover:not(:active):not(.active) {
    color: theme-color("primary");
  }

  // Small vertical dash below button
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 50%;
    width: $border-width;
    height: ($grid-gutter-width / 2 + $border-width);
    background: theme-color("primary");
    transform: translateX(-50%);
  }

  @include icon-size(40px);

  // Handle icon animation on open
  .icon {
    margin: 0 auto;
    transform: rotate(0deg);

    @include transition(transform 0.6s cubic-bezier(0.5, 0.53, 0.37, 0.94));
  }

  &:not([disabled]):not(.disabled):active {
    &:after {
      height: ($grid-gutter-width / 2 + $border-width * 2.5);
    }
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    border-width: 0;
    box-shadow: 0 0 0 2px inset color("primary-darker");
  }

  // With navbar open
  .open & {
    .icon {
      transform: rotate(180deg);
    }
  }

  // If we don't have a current path to display, remove the :after
  body:not(.open) &.navbar-toggle-simple {
    &:after {
      content: none;
    }
  }
}

// The whole navbar component
.navbar-container {
  display: flex;
  position: relative;
  z-index: $zindex-modal-backdrop;
  flex-direction: column;
  align-items: center;

  .open & {
    z-index: $zindex-modal;
  }
}

// Three items always visible below navbar toggle
.navbar-filters {
  position: relative;
  border-radius: $btn-border-radius;

  .btn-primary {
    z-index: 10;

    &:not([disabled]):not(.disabled):active {
      transform: scale(1.05);
    }
  }
}

// Single item in navbar filters
.navbar-filter {
  position: absolute;
  top: 0;
  order: 0;
  z-index: 0;
  padding-right: $input-btn-padding-x / 1.5;
  padding-left: $input-btn-padding-x / 1.5;
  min-width: 0;
  border-color: $purple;

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    background-color: $purple;
    border-color: $purple;
  }

  &:not([disabled]):not(.disabled).active {
    box-shadow: none;
  }

  // First filter item (is actually the second in the markup)
  &:not(:last-of-type) {
    right: 100%;
    margin-right: -1.5rem;
    padding-right: 2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // Last filter item
  &:last-of-type {
    left: 100%;
    order: 2;
    margin-left: -1.5rem;
    padding-left: 2rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // override focus effect to avoid ugly effect
  &:focus,
  &.focus,
  &:not([disabled]):not(.disabled):active:focus,
  &:not([disabled]):not(.disabled).active:focus {
    box-shadow: inset 0 0 0 2px lighten(theme-color("primary"), 30%);
  }
}

.navbar-current {
  .open & {
    z-index: -1;
  }
}

// Navbar
/////////

.navbar {
  display: flex;
  position: absolute;
  top: $navbar-icon-size + $grid-gutter-width / 2 - $border-width;
  left: 50%;
  z-index: 20;
  flex-direction: column;
  justify-content: space-between;
  height: 0;
  padding: $border-width $spacer;
  overflow: hidden;
  opacity: 0;
  transform: translateX(-50%);

  @include transition(
    height 0.3s ease 0.1s,
    opacity 0.145s ease
  );

  // Long vertical line drawn below items
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 20px;
    left: 50%;
    width: $border-width;
    height: 0;
    background: theme-color("primary");
    transform: translateX(-50%);

    @include transition(height 0.1s ease-out);
  }

  // Styles of navbar when navbar is open
  .open & {
    opacity: 1;
    height: 65vh;

    @include transition(
      height 0s ease,
      opacity 0.3s ease
    );

    &:after {
      height: 90%;

      @include transition(height 0.34s ease-out);
    }
  }
}

.navbar-settings {
  display: flex;
  position: fixed;
  bottom: -100vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  opacity: 0;

  .open & {
    opacity: 1;
    bottom: ($grid-gutter-width / 2);

    @include transition(
      height 0s ease,
      opacity 0.3s ease
    );
  }

  .btn {
    background-color: color("primary-lightest");
    color: theme-color("primary");
  }

  .bg-primary & {
    .btn {
      background-color: color("primary-light");
      color: $white;

      .icon,
      .icon svg {
        color: $white !important;
      }
    }
  }
}

// White overlay when navbar is open
.navbar-overlay {
  position: fixed;
  z-index: -1;
  top: -200vh;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba($white, 0.8);
  pointer-events: none;
  transition: opacity 0.3s ease;

  .open & {
    top: 0;
    opacity: 1;
    pointer-events: initial;
  }
}

// Navbar item, displayed as cards with size relative to screen (we expect 5 items)
.nav-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 17vh;
  height: 15vh;
  min-width: 100px;
  min-height: 100px;
  border-radius: $border-radius-lg;
  border: $border-width solid theme-color("primary");
  padding-bottom: 2vh;
  font-weight: 600;

  @include transition(top 0.1s ease-out, background-color 0.2s ease-out);

  @for $i from 2 through 5 {
    &:nth-child(#{$i}) {
      top: percentage(-0.08 * $i);
    }
  }

  @include button-variant(color("primary-light"), theme-color("primary"), theme-color("primary"));
  @include drop-shadow(bottom);
  @include icon-size(6vh, 38px);

  // Override background-color set in button variant
  background-color: color("primary-lightest");
  color: theme-color("primary");

  @include hover {
    &:not(:active):not(.active) {
      text-decoration: none;
      background-color: darken(color("primary-lightest"), 7%);
      color: theme-color("primary");
    }
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    text-decoration: none;

    &,
    svg {
      color: $white;
    }
  }

  &:not([disabled]):not(.disabled).active {
    z-index: 20;
  }

  &:not([disabled]):not(.disabled):active {
    transform: scale(0.95);
  }

  // Styles when navbar is open
  .open & {
    top: 0;

    @include transition(top 0.3s ease-out, background-color 0.2s ease-out);
  }

  .icon {
    margin: auto 0;
  }
}

// Class `.open` is added to body when the navbar is open
// We avoid the body to scroll when navbar is open
body.open {
  max-height: 100vh;
  overflow: hidden;
}

// Override the styles on the onboarding page
.bg-primary .fullpage-wrapper {
  .navbar {
    &:after {
      background-color: $white;
    }
  }

  .navbar-toggle {
    background-color: color('primary-light');
    color: $white;
    border-color: $white;

    @include drop-shadow('bottom', color('primary-darker'));

    @include hover-focus-active {
      color: $white;
      background-color: darken(color('primary-light'), 10%);
    }

    &:after {
      background-color: $white;
    }
  }

  .navbar-overlay {
    background-color: theme-color('primary');
  }

  .nav-item {
    @include button-variant(color("primary-light"), theme-color("primary"), theme-color("primary"));

    border-color: $white;

    @include hover {
      border-color: $white;
    }
  }
}
