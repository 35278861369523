@charset 'utf-8';

.form-group,
.form-date {
  label {
    font-weight: 600;
  }
}

.form-control {
  padding: $input-btn-padding-y ($input-btn-padding-x / 2);

  &[type=time] {
    padding-right: 0.2rem !important;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

[data-required] {
  label:not(.btn):not(.checkbox-image) {
    &:after {
      content: '*';
    }
  }
}

[data-toggle=buttons] {
  .btn {
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}

.pac-container {
  z-index: $zindex-modal + 10;
}

.form-control-file {
  input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
}

.bg-primary {
  @include form-validation-state("invalid", theme-color-level('danger', -4));
}

.selectize-input {
  input {
    width: 100% !important;
  }
}
