@charset 'utf-8';

.modal:not(.fade) {
  margin-top: 75px;
  margin-bottom: 75px;
}

.modal-open {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.modal-dialog {
  width: calc(100% - #{$grid-gutter-width});
  height: 75vh;
  max-height: calc(100vh - 150px);
  text-align: left;
  margin-right: ($grid-gutter-width / 2);
  margin-left: ($grid-gutter-width / 2);

  .modal:not(.fade) & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modal-header {
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  flex-shrink: 0;

  .alert {
    margin-top: $spacer;
    margin-bottom: 0;

    .close {
      margin: 0;
    }
  }
}

.modal-content {
  height: 100%;
  overflow: auto;
}

.modal-title {
  font-weight: $font-weight-normal;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: min-content;

  .fullpage-wrapper {
    min-height: 0;
  }
}

.modal-footer {
  flex-shrink: 0;
}

// We sometimes need to have a modal look but it's not really a modal
.modal.force-show,
.modal-open .modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
