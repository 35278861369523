@charset "utf-8";

.header {
  > .row > * {
    // basic column properties
    @include make-col-ready();
    @include make-col(4);

    &:last-child {
      text-align: right;
    }
  }
}

// Drupal
#toolbar-administration {
  z-index: ($zindex-tooltip * 2);
}
