@charset 'utf-8';

.step-nav {
  position: relative;
  flex-wrap: nowrap;
  justify-content: space-between;
  counter-reset: step-nav;
  margin-top: $spacer;
  padding-right: 0;
  width: 100%;

  // Fix issue in Safari 9
  flex-basis: initial;

  @include list-unstyled;

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    right: $spacer;
    left: $spacer;
    width: calc(100% - #{$spacer * 2});
    height: $border-width;
    background: $white;
    transform: translateY(-50%);
  }
}

.step-nav-item {
  position: relative;
  z-index: 1;
}

.step-nav-link {
  line-height: 1.5;

  &:after {
    counter-increment: step-nav;
    content: counter(step-nav);
    display: block;
    height: 100%;
  }
}
