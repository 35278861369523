@charset "utf-8";

.ie11 {
  // Inputs
  .form-control:-ms-input-placeholder { color: $input-placeholder-color; } // stylelint-disable-line selector-no-vendor-prefix

  // Calendar
  .calendar {
    display: inline-block;
    width: 650px;

    & > *:nth-child(7n + 1) {
      clear: both;
    }
  }

  .btn-circle {
    width: 1.75rem;
    height: 1.75rem;
  }

  .calendar-head,
  .calendar-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 3.125rem;
    height: 3.125rem;
    margin: 0 2% 1%;
  }

  .calendar-item {
    .flag-number {
      left: 42%;
    }
  }

  .calendar-item {
    .flag {
      left: -20%;
    }

    .flag-number {
      left: 42%;
    }
  }

  // Photos section
  .img-grid {
    display: block;

    > * {
      float: left;
      width: 47.5%;
      margin-bottom: 5%;

      &:nth-child(2n) {
        margin-left: 2.5%;
      }

      &:nth-child(2n+1) {
        margin-right: 2.5%;
      }
    }
  }

  .gallery-item {
    .card {
      height: 0;
      padding: 0 0 100%;
    }
  }

  // Themes grid
  .btn-grid {
    display: block;

    @include clearfix;

    .btn {
      float: left;
      width: 20%;

      &:nth-child(5n+1) {
        width: percentage(1/3);
      }

      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }

  // Fixes Google Maps Autocomplete position. Also see the JS code.
  .pac-container {
    position: absolute !important;
    top: 70px !important;
    left: 1px !important;
  }

  // Fixes the backface visibility of the card when flipped
  .flip.card-flippable .card-back,
  .flip.card-flippable .card-front,
  .flip.card-flippable .collapse {
    backface-visibility: visible;
  }

  // Pagination is buggy with grid
  .pagination-wrapper {
    display: flex;
  }

  .sticky-bottom,
  .sticky-top {
    pointer-events: auto;
  }
}

// only target Edge
@supports (-ms-ime-align: auto) {
  .sticky-bottom,
  .sticky-top {
    pointer-events: auto;
  }

  .sticky-top {
    position: relative;
  }

  .modal-body {
    overflow-y: visible;
  }

  .calendar-item {
    .flag {
      left: -20%;
    }

    &.active .flag {
      left: 20%;
    }

    .flag-number {
      left: 42%;
    }
  }
}
