@charset 'utf-8';

@mixin qs-alert-variant($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  > a,
  .alert-link {
    color: darken($background, 35%);
  }
}

//
// Base styles
//

.alert {
  position: relative;
  width: 100%;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  text-align: center;
  -webkit-font-smoothing: auto;

  @include border-radius($alert-border-radius);
  @include drop-shadow('bottom', color('dark'));

  ul,
  ol {
    text-align: left;
  }

  > a {
    @extend .alert-link;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-padding-x * 2 + $close-font-size;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: $alert-padding-y $alert-padding-x;
    line-height: 0;
    color: inherit;
    opacity: 1;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
.alert-info {
  @include qs-alert-variant(color('info-light'), color-level(color('info-light'), 0.5), $white);
}

.alert-success {
  @include qs-alert-variant(color('secondary-light'), color-level(color('secondary-light'), 0.5), $white);
}

.alert-danger {
  @include qs-alert-variant(color('danger-lighter'), color-level(color('danger-lighter'), 1), $white);
}

.alert-warning {
  @include qs-alert-variant(color('warning-light'), color-level(color('warning-light'), -3), $white);
}
