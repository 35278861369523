@charset 'utf-8';

.flag {
  color: $body-color;
  // Fixes a bug with the filter drop-shadow when animated
  transform: translateZ(0);

  svg {
    fill: $white;
  }

  @include flag-size(40px);

  .card > & {
    position: absolute;
    top: -5px;
    right: ($grid-gutter-width / 2);
  }
}

.flag-shield {
  margin-top: -3px;

  @include flag-size(43px * 1.1);
}

.flag-lg {
  @include flag-size(60px);

  &.flag-shield {
    @include flag-size(60px * 1.1);
  }
}

.calendar-item {
  .flag-info {
    margin-top: -3px;
  }
}

@each $color, $value in $flag-colors {
  &.flag-#{$color} {
    color: $white;

    svg {
      fill: $value;
    }

    @if $enable-shadows {
      filter: drop-shadow(0 0 3px $value);
    }
  }

  &.flag-outline-#{$color} {
    color: $value;

    @if $enable-shadows {
      filter: drop-shadow(0 0 3px currentColor);
    }
  }
}
